export default defineNuxtPlugin(() => {
  let result = false

  function isSafari() {
    const ua = navigator.userAgent.toLowerCase()
    return ua.indexOf('safari') > -1 && ua.indexOf('chrome') == -1
  }
  if (process.client) {
    result = isSafari()
  }

  return {
    provide: {
      isSafari: result,
    },
  }
})
