<template>
  <div>
    <NuxtLoadingIndicator
      :color="'repeating-linear-gradient(to right, rgb(var(--color-primary-400)) 0%,rgb(var(--color-primary-500)) 50%,rgb(var(--color-primary-600)) 100%)'"
    />

    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>

    <ClientOnly>
      <CookieConsent />
    </ClientOnly>

    <UNotifications />
  </div>
</template>
<script setup lang="ts">
const { data: docsNavigation } = await useAsyncData('navigation', () =>
  fetchContentNavigation()
)

provide('docsNavigation', docsNavigation)
const lenis = ref<any>(null)

onMounted(() => {
  lenis.value = useSmoothScroll()
})
onUnmounted(() => {
  lenis.value?.destroy()
})

provide('lenis', lenis)
</script>
