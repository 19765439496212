import Lenis from '@studio-freight/lenis'
import gsap from 'gsap'
import { usePreferredReducedMotion } from '@vueuse/core'

export const useSmoothScroll = () => {
  const reducedMotion = usePreferredReducedMotion()
  if (reducedMotion.value === 'reduce') return

  const scrollLenis = new Lenis({
    duration: 1.3,
    orientation: 'vertical',
    gestureOrientation: 'vertical',
    smoothWheel: true,
  })

  gsap.ticker.add((time) => {
    scrollLenis.raf(time * 1000)
  })

  gsap.ticker.lagSmoothing(0)
  return scrollLenis
}
