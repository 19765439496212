const _inherit = "inherit"
const _current = "currentColor"
const _transparent = "transparent"
const _black = "#000000"
const _white = "#ffffff"
const _slate = {"50":"#F8FAFC","100":"#F1F5F9","200":"#E2E8F0","300":"#CBD5E1","400":"#94A3B8","500":"#64748B","600":"#475569","700":"#334155","800":"#1E293B","900":"#0F172A","950":"#020617"}
const _gray = {"50":"rgb(var(--color-gray-50) / <alpha-value>)","100":"rgb(var(--color-gray-100) / <alpha-value>)","200":"rgb(var(--color-gray-200) / <alpha-value>)","300":"rgb(var(--color-gray-300) / <alpha-value>)","400":"rgb(var(--color-gray-400) / <alpha-value>)","500":"rgb(var(--color-gray-500) / <alpha-value>)","600":"rgb(var(--color-gray-600) / <alpha-value>)","700":"rgb(var(--color-gray-700) / <alpha-value>)","800":"rgb(var(--color-gray-800) / <alpha-value>)","900":"rgb(var(--color-gray-900) / <alpha-value>)","950":"rgb(var(--color-gray-950) / <alpha-value>)"}
const _zinc = {"50":"#fafafa","100":"#f4f4f5","200":"#e4e4e7","300":"#d4d4d8","400":"#a1a1aa","500":"#71717a","600":"#52525b","700":"#3f3f46","800":"#27272a","900":"#18181b","950":"#09090b"}
const _neutral = {"50":"#fafafa","100":"#f5f5f5","200":"#e5e5e5","300":"#d4d4d4","400":"#a3a3a3","500":"#737373","600":"#525252","700":"#404040","800":"#262626","900":"#171717","950":"#0a0a0a"}
const _stone = {"50":"#fafaf9","100":"#f5f5f4","200":"#e7e5e4","300":"#d6d3d1","400":"#a8a29e","500":"#78716c","600":"#57534e","700":"#44403c","800":"#292524","900":"#1c1917","950":"#0c0a09"}
const _red = {"50":"#fef2f2","100":"#fee2e2","200":"#fecaca","300":"#fca5a5","400":"#f87171","500":"#ef4444","600":"#dc2626","700":"#b91c1c","800":"#991b1b","900":"#7f1d1d","950":"#450a0a"}
const _orange = "var(--j-orange)"
const _amber = {"50":"#fffbeb","100":"#fef3c7","200":"#fde68a","300":"#fcd34d","400":"#fbbf24","500":"#f59e0b","600":"#d97706","700":"#b45309","800":"#92400e","900":"#78350f","950":"#451a03"}
const _yellow = "var(--j-yellow)"
const _lime = {"50":"#f7fee7","100":"#ecfccb","200":"#d9f99d","300":"#bef264","400":"#a3e635","500":"#84cc16","600":"#65a30d","700":"#4d7c0f","800":"#3f6212","900":"#365314","950":"#1a2e05"}
const _green = "var(--j-green)"
const _emerald = {"50":"#ecfdf5","100":"#d1fae5","200":"#a7f3d0","300":"#6ee7b7","400":"#34d399","500":"#10b981","600":"#059669","700":"#047857","800":"#065f46","900":"#064e3b","950":"#022c22"}
const _teal = "var(--j-teal)"
const _cyan = "var(--j-cyan)"
const _sky = {"50":"#F1F9FE","100":"#E3F1FB","200":"#C0E5F7","300":"#88D0F1","400":"#32B0E5","500":"#20A0D7","600":"#1381B6","700":"#106694","800":"#12577A","900":"#144966","950":"#0D2F44"}
const _blue = "var(--j-blue)"
const _indigo = {"50":"#F4F6FA","100":"#E5E9F4","200":"#D1D9EC","300":"#B1C0DF","400":"#8CA0CE","500":"#7083C1","600":"#5D6BB3","700":"#525BA3","800":"#474D86","900":"#3D426B","950":"#282A43"}
const _violet = {"50":"#f5f3ff","100":"#ede9fe","200":"#ddd6fe","300":"#c4b5fd","400":"#a78bfa","500":"#8b5cf6","600":"#7c3aed","700":"#6d28d9","800":"#5b21b6","900":"#4c1d95","950":"#2e1065"}
const _purple = "var(--j-purple)"
const _fuchsia = {"50":"#fdf4ff","100":"#fae8ff","200":"#f5d0fe","300":"#f0abfc","400":"#e879f9","500":"#d946ef","600":"#c026d3","700":"#a21caf","800":"#86198f","900":"#701a75","950":"#4a044e"}
const _pink = "var(--j-pink)"
const _rose = {"50":"#fff1f2","100":"#ffe4e6","200":"#fecdd3","300":"#fda4af","400":"#fb7185","500":"#f43f5e","600":"#e11d48","700":"#be123c","800":"#9f1239","900":"#881337","950":"#4c0519"}
const _background = "rgb(var(--ui-background) / <alpha-value>)"
const _foreground = "rgb(var(--ui-foreground) / <alpha-value>)"
const _base = "var(--j-base)"
const _bg = "rgb(var(--bg-color) / <alpha-value>)"
const _overlay = "rgb(var(--overlay) / <alpha-value>)"
const _brown = "var(--j-brown)"
const _contessa = {"50":"#FBF6F5","100":"#F6ECEA","200":"#F0DCD8","300":"#E4C3BD","400":"#D3A096","500":"#BA7264","600":"#AA6558","700":"#8E5347","800":"#77463D","900":"#643F38","950":"#351E1A"}
const _flamingo = {"50":"#FDF4F3","100":"#FBE8E5","200":"#F9D4CF","300":"#F4B5AD","400":"#EC8A7D","500":"#E06353","600":"#C74433","700":"#AB392A","800":"#8E3226","900":"#762F26","950":"#40150F"}
const _pinot = {"50":"#FBF4F9","100":"#FBF4F9","200":"#F9EAF4","300":"#EDB4D7","400":"#E185BC","500":"#D55FA2","600":"#C14184","700":"#AB316D","800":"#8A2A58","900":"#74274C","950":"#46112A"}
const _orchid = {"50":"#FDF6FD","100":"#F9ECFB","200":"#F3D8F6","300":"#EBBAED","400":"#DE90E2","500":"#CC65D0","600":"#B245B4","700":"#943794","800":"#7A2E79","900":"#61295F","950":"#411140"}
const _plum = {"50":"#FBF7FC","100":"#F6EFF8","200":"#EDDDF1","300":"#E0C2E5","400":"#CC9ED4","500":"#B476BF","600":"#9858A1","700":"#79437F","800":"#683B6D","900":"#59345B","950":"#371939"}
const _paradiso = {"50":"#F4F9F9","100":"#DAEDEC","200":"#B6D9D7","300":"#89BFBE","400":"#60A0A1","500":"#437D7F","600":"#37676A","700":"#2F5356","800":"#294446","900":"#253B3C","950":"#112122"}
const _amazon = {"50":"#F1F8F4","100":"#DEEDE3","200":"#BFDBCA","300":"#94C1A8","400":"#66A182","500":"#437F62","600":"#336850","700":"#295341","800":"#224335","900":"#1D372C","950":"#0F1F19"}
const _wasabi = {"50":"#F7F9EC","100":"#ECF2D5","200":"#DBE6B0","300":"#C2D482","400":"#A9C15A","500":"#8CA63C","600":"#71892E","700":"#536526","800":"#445123","900":"#3B4621","950":"#1D250E"}
const _primary = {"50":"rgb(var(--color-primary-50) / <alpha-value>)","100":"rgb(var(--color-primary-100) / <alpha-value>)","200":"rgb(var(--color-primary-200) / <alpha-value>)","300":"rgb(var(--color-primary-300) / <alpha-value>)","400":"rgb(var(--color-primary-400) / <alpha-value>)","500":"rgb(var(--color-primary-500) / <alpha-value>)","600":"rgb(var(--color-primary-600) / <alpha-value>)","700":"rgb(var(--color-primary-700) / <alpha-value>)","800":"rgb(var(--color-primary-800) / <alpha-value>)","900":"rgb(var(--color-primary-900) / <alpha-value>)","950":"rgb(var(--color-primary-950) / <alpha-value>)","DEFAULT":"rgb(var(--color-primary-DEFAULT) / <alpha-value>)"}
const _cool = {"50":"#f9fafb","100":"#f3f4f6","200":"#e5e7eb","300":"#d1d5db","400":"#9ca3af","500":"#6b7280","600":"#4b5563","700":"#374151","800":"#1f2937","900":"#111827","950":"#030712"}
const config = { "inherit": _inherit, "current": _current, "transparent": _transparent, "black": _black, "white": _white, "slate": _slate, "gray": _gray, "zinc": _zinc, "neutral": _neutral, "stone": _stone, "red": _red, "orange": _orange, "amber": _amber, "yellow": _yellow, "lime": _lime, "green": _green, "emerald": _emerald, "teal": _teal, "cyan": _cyan, "sky": _sky, "blue": _blue, "indigo": _indigo, "violet": _violet, "purple": _purple, "fuchsia": _fuchsia, "pink": _pink, "rose": _rose, "background": _background, "foreground": _foreground, "base": _base, "bg": _bg, "overlay": _overlay, "brown": _brown, "contessa": _contessa, "flamingo": _flamingo, "pinot": _pinot, "orchid": _orchid, "plum": _plum, "paradiso": _paradiso, "amazon": _amazon, "wasabi": _wasabi, "primary": _primary, "cool": _cool, "block-1": "var(--j-block-1)", "block-2": "var(--j-block-2)", "block-3": "var(--j-block-3)", "block-4": "var(--j-block-4)", "block-outline": "var(--j-block-outline)", "bg-plain": "var(--j-bg-plain)", "text-1": "var(--j-text-1)", "text-2": "var(--j-text-2)", "text-3": "var(--j-text-3)", "j-red": "var(--j-red)", "active-tab-text": "var(--j-active-tab-text)", "active-tab-bg": "var(--j-active-tab-bg)",  }
export { config as default, _inherit, _current, _transparent, _black, _white, _slate, _gray, _zinc, _neutral, _stone, _red, _orange, _amber, _yellow, _lime, _green, _emerald, _teal, _cyan, _sky, _blue, _indigo, _violet, _purple, _fuchsia, _pink, _rose, _background, _foreground, _base, _bg, _overlay, _brown, _contessa, _flamingo, _pinot, _orchid, _plum, _paradiso, _amazon, _wasabi, _primary, _cool }