import colors from '#tailwind-config/theme/colors'

export default defineNuxtPlugin({
  enforce: 'post',
  setup() {
    const appConfig = useAppConfig()

    const root = computed(() => {
      const primary: Record<string, string> | undefined =
        // @ts-ignore
        colors[appConfig.ui.primary]
      // @ts-ignore
      const gray: Record<string, string> | undefined = colors[appConfig.ui.gray]

      return `:root {
        ${Object.entries(primary || colors.green)
          .map(([key, value]) => `--color-primary-${key}: ${hexToRgb(value)};`)
          .join('\n')}
        --color-primary-DEFAULT: var(--color-primary-500);
  
        ${Object.entries(gray || colors.cool)
          .map(([key, value]) => `--color-gray-${key}: ${hexToRgb(value)};`)
          .join('\n')}
        --bg-light: var(--bg-light-${appConfig.ui.primary});
        --bg-dark: var(--bg-dark-${appConfig.ui.primary});
        --overlay-dark: var(--overlay-dark-${appConfig.ui.primary});
        }
  
  

  
        .dark {
          --color-primary-DEFAULT: var(--color-primary-400);
        }
        `
    })

    if (import.meta.client) {
      watch(root, () => {
        window.localStorage.setItem('juno-ui-root', root.value)
      })

      appConfig.ui.primary =
        window.localStorage.getItem('nuxt-ui-primary') || appConfig.ui.primary
      appConfig.ui.gray =
        window.localStorage.getItem('nuxt-ui-gray') || appConfig.ui.gray
    }

    if (import.meta.server) {
      useHead({
        script: [
          {
            innerHTML: `
                if (localStorage.getItem('juno-ui-root')) {
                  document.querySelector('style#juno-ui-colors').innerHTML = localStorage.getItem('juno-ui-root')
                }`.replace(/\s+/g, ' '),
            type: 'text/javascript',
            tagPriority: -1,
          },
        ],
      })
    }
  },
})
