import { computed } from 'vue'
import colors from '#tailwind-config/theme/colors'
import { defineNuxtPlugin, useAppConfig, useNuxtApp, useHead } from '#imports'

export default defineNuxtPlugin(() => {
  const appConfig = useAppConfig()
  const nuxtApp = useNuxtApp()

  const root = computed(() => {
    const primary: Record<string, string> | undefined =
      // @ts-ignore
      colors[appConfig.ui.primary]
    // @ts-ignore
    const gray: Record<string, string> | undefined = colors[appConfig.ui.gray]

    return `:root {
      ${Object.entries(primary || colors.green)
        .map(([key, value]) => `--color-primary-${key}: ${hexToRgb(value)};`)
        .join('\n')}
      --color-primary-DEFAULT: var(--color-primary-500);

      ${Object.entries(gray || colors.cool)
        .map(([key, value]) => `--color-gray-${key}: ${hexToRgb(value)};`)
        .join('\n')}

      --bg-light: var(--bg-light-${appConfig.ui.primary});
      --bg-dark: var(--bg-dark-${appConfig.ui.primary});
      --overlay-dark: var(--overlay-dark-${appConfig.ui.primary});
      }




      .dark {
        --color-primary-DEFAULT: var(--color-primary-400);
      }
      `
  })

  // Head
  const headData: any = {
    style: [
      {
        innerHTML: () => root.value,
        tagPriority: -2,
        id: 'juno-ui-colors',
      },
    ],
  }

  // SPA mode
  if (
    import.meta.client &&
    nuxtApp.isHydrating &&
    !nuxtApp.payload.serverRendered
  ) {
    const style = document.createElement('style')

    style.innerHTML = root.value
    style.setAttribute('data-juno-ui-colors', '')
    document.head.appendChild(style)

    headData.script = [
      {
        innerHTML:
          "document.head.removeChild(document.querySelector('[data-juno-ui-colors]'))",
      },
    ]
  }

  useHead(headData)
})
