export default defineAppConfig({
  getStartedUrl: 'https://login.juno.one/get-started',
  header: {
    logo: {
      alt: 'juno logo',
      light: '/img/logo.png',
      dark: '/img/logo.png',
    },
  },
  nuxtIcon: {
    size: '24px', // default <Icon> size applied
    // iconifyApiOptions: {
    //   url: 'https://iconify-holy-sun-9951.fly.dev',
    // },
  },
  ui: {
    /*     strategy: "override", */
    primary: 'pinot',
    gray: 'zinc',
    variables: {
      light: {
        background: 'var(--bg-color)',
        foreground: 'var(--j-text-1)',
      },
      dark: {
        background: 'var(--bg-color)',
        foreground: 'var(--j-text-1)',
      },
      header: {
        height: '4rem',
      },
    },
    button: {
      base: 't-button font-medium',
      variant: {
        outline:
          'ring-1 ring-inset ring-current text-{color}-500 dark:text-{color}-400 hover:bg-{color}-400/10 disabled:bg-transparent dark:hover:bg-{color}-400/10 dark:disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
        ghost:
          'text-{color}-500 dark:text-{color}-400 hover:bg-{color}-400/10 disabled:bg-transparent dark:hover:bg-{color}-400/10 dark:disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
        solid:
          'shadow-sm text-white dark:text-white bg-{color}-600 hover:bg-{color}-600 disabled:bg-{color}-500 dark:bg-{color}-600 dark:hover:bg-{color}-500 dark:disabled:bg-{color}-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-{color}-500 dark:focus-visible:outline-{color}-400',
      },
      color: {
        gray: {
          ghost:
            'text-gray-700 dark:text-gray-200 hover:text-gray-900 dark:hover:text-white hover:bg-block-2 dark:hover:bg-gray-800 focus-visible:ring-inset focus-visible:ring-2 focus-visible:ring-primary-500 dark:focus-visible:ring-primary-400',
        },
      },
    },
    meter: {
      wrapper: 'w-full flex flex-col gap-2',
      indicator: {
        container: 'min-w-fit transition-all',
        text: 'text-gray-400 dark:text-gray-500 text-end',
        size: {
          '2xs': 'text-xs',
          xs: 'text-xs',
          sm: 'text-sm',
          md: 'text-sm',
          lg: 'text-sm',
          xl: 'text-base',
          '2xl': 'text-base',
        },
      },
      meter: {
        base: 'appearance-none block w-full bg-none overflow-y-hidden',
        background: 'bg-gray-200 dark:bg-gray-700',
        color: 'text-{color}-500 dark:text-{color}-400',
        ring: '',
        rounded: 'rounded-full',
        shadow: '',
        size: {
          '2xs': 'h-px',
          xs: 'h-0.5',
          sm: 'h-1',
          md: 'h-2',
          lg: 'h-3',
          xl: 'h-4',
          '2xl': 'h-5',
        },
        appearance: {
          inner:
            '[&::-webkit-meter-inner-element]:block [&::-webkit-meter-inner-element]:relative [&::-webkit-meter-inner-element]:border-none [&::-webkit-meter-inner-element]:bg-none [&::-webkit-meter-inner-element]:bg-transparent',
          meter:
            '[&::-webkit-meter-bar]:border-none [&::-webkit-meter-bar]:bg-none [&::-webkit-meter-bar]:bg-transparent',
          bar: '[&::-webkit-meter-optimum-value]:border-none [&::-webkit-meter-optimum-value]:bg-none [&::-webkit-meter-optimum-value]:bg-current',
          value:
            '[&::-moz-meter-bar]:border-none [&::-moz-meter-bar]:bg-none [&::-moz-meter-bar]:bg-current',
        },
        bar: {
          transition:
            '[&::-webkit-meter-optimum-value]:transition-all [&::-moz-meter-bar]:transition-all duration-1000',
          ring: '',
          rounded:
            '[&::-webkit-meter-optimum-value]:rounded-full [&::-moz-meter-bar]:rounded-full',
          size: {
            '2xs':
              '[&::-webkit-meter-optimum-value]:h-px [&::-moz-meter-bar]:h-px',
            xs: '[&::-webkit-meter-optimum-value]:h-0.5 [&::-moz-meter-bar]:h-0.5',
            sm: '[&::-webkit-meter-optimum-value]:h-1 [&::-moz-meter-bar]:h-1',
            md: '[&::-webkit-meter-optimum-value]:h-2 [&::-moz-meter-bar]:h-2',
            lg: '[&::-webkit-meter-optimum-value]:h-3 [&::-moz-meter-bar]:h-3',
            xl: '[&::-webkit-meter-optimum-value]:h-4 [&::-moz-meter-bar]:h-4',
            '2xl':
              '[&::-webkit-meter-optimum-value]:h-5 [&::-moz-meter-bar]:h-5',
          },
        },
      },
      label: {
        base: 'flex gap-2 items-center',
        text: 'truncate',
        color: 'text-{color}-500 dark:text-{color}-400',
        size: {
          '2xs': 'text-xs',
          xs: 'text-xs',
          sm: 'text-sm',
          md: 'text-sm',
          lg: 'text-sm',
          xl: 'text-base',
          '2xl': 'text-base',
        },
      },
      color: {
        white: 'text-white dark:text-black',
        black: 'text-black dark:text-white',
        gray: 'text-gray-500 dark:text-gray-400',
      },
      default: {
        size: 'md',
        color: 'primary',
      },
    },
    modal: {
      overlay: {
        background: 'bg-black/40 dark:bg-black/40 blur-sm',
      },
      wrapper: 'border-1 border-block-3 dark:border-block-3',
      background: 'bg-white dark:bg-bg',
    },
    container: {
      base: 'container',
      padding: '',
      constrained: '',
    },
    header: {
      container: 'w-full',
    },

    page: {
      header: {
        wrapper: 'border-block-2 dark:border-block-2',
        description: 'text-text-2 dark:text-text-2',
        title: 'text-text-1 dark:text-text-1 tracking-tight',
      },
    },
    divider: {
      container: {
        base: 'text-text-1 dark:text-text-1',
      },
      border: {
        base: 'border-block-2 dark:border-block-2',
      },
    },
    navigation: {
      accordion: {
        wrapper: 'space-y-3',
        container: 'space-y-3',
        item: {
          padding: '',
          color: 'text-inherit dark:text-inherit',
        },
        button: {
          base: 'flex items-center gap-1.5 group w-full focus-visible:outline-primary',
          active: 'text-primary border-current',
          inactive: 'border-transparent',
          level: 'border-l -ml-px pl-3.5',
          icon: {
            base: 'w-5 h-5 flex-shrink-0',
          },
          trailingIcon: {
            name: 'i-heroicons-chevron-down-20-solid',
            base: 'w-5 h-5 ms-auto transform transition-transform duration-200 flex-shrink-0 mr-1.5',
            active: 'text-text-1 dark:text-text-1',
            inactive:
              'text-text-2 dark:text-text-1 group-hover:text-text-1 -rotate-90',
          },
          label: 'text-sm/6 font-semibold truncate',
        },
        tree: 'border-l border-gray-200 dark:border-gray-800',
      },
      links: {
        wrapper: 'space-y-3',
        wrapperLevel: 'space-y-1.5',
        base: 'flex items-center gap-1.5 group text-text-2 dark:text-text-2',
        active: 'text-primary font-medium border-current',
        inactive:
          'text-text-2 dark:text-text-2 hover:text-text-1 dark:hover:text-text-1 border-transparent hover:border-gray-500 dark:hover:border-gray-400',
        level: 'border-l -ml-px pl-4',
        icon: {
          base: 'w-5 h-5 flex-shrink-0',
        },
        badge: {
          base: 'rounded-full',
        },
        label: 'text-sm/6 truncate',
      },
    },
    commandPalette: {
      wrapper: 'divide-block-2 dark:divide-block-2',
      container: 'divide-block-2 dark:divide-block-2',
      input: {
        base: 'placeholder-text-3 dark:placeholder-text-3 text-text-2 dark:text-text-2',
        icon: {
          base: 'text-text-2 dark:text-text-2',
        },
      },
      emptyState: {
        label: 'text-text-2 dark:text-text-2',
        queryLabel: 'text-text-1 dark:text-text-1',
        icon: 'text-text-2 dark:text-text-2',
      },
      group: {
        label: 'text-text-1 dark:text-text-1',
        container: 'text-text-1 dark:text-text-1',
        command: {
          active: 'bg-block-2 dark:bg-block-2 text-text-1 dark:text-text-1',
          prefix: 'text-text-2 dark:text-text-2',
          suffix: 'text-text-3 dark:text-text-3',
          icon: {
            active: 'text-text-1 dark:text-text-1',
            inactive: 'text-text-2 dark:text-text-2',
          },
          selectedIcon: {
            base: 'text-text-1 dark:text-text-1',
          },
        },
        active: 'text-text-1 dark:text-text-1',
        inactive: 'text-text-2 dark:text-text-2',
      },
    },
    input: {
      placeholder: 'placeholder-text-3 dark:placeholder-text-3',
      color: {
        white: {
          outline:
            'bg-block-2 dark:bg-block-2 text-text-1 dark:text-text-1 ring-block-3 dark:ring-block-3',
        },
      },
    },
    textarea: {
      placeholder: 'placeholder-text-3 dark:placeholder-text-3',
      color: {
        white: {
          outline:
            'bg-block-2 dark:bg-block-2 text-text-1 dark:text-text-1 ring-block-3 dark:ring-block-3',
        },
      },
    },
    checkbox: {
      background: 'bg-block-2 dark:bg-block-2',
      border: 'border-block-3 dark:border-block-3',
    },
    kbd: {
      base: 'text-text-1 dark:text-text-1',
      background: 'bg-block-2 dark:bg-block-2',
      ring: 'ring-block-2 dark:ring-block-2',
    },
    landing: {
      faq: {
        wrapper: 'divide-y divide-block-2 dark:divide-block-2 -mt-6',
        item: {
          size: 't-body text-body text-text-2 dark:text-text-2',
          padding: 'py-6',
        },
        button: {
          label: 'text-text-1 dark:text-text-1 t-body__bold lg:t-subtitle',
        },
      },
    },
    pricing: {
      toggle: {
        wrapper:
          'bg-block-1 ring-0 flex items-center relative h-8 w-auto flex-shrink-0 cursor-pointer rounded-md p-1 w-full focus:outline-none',
        marker:
          'w-1/2 text-white dark:text-gray-900 pointer-events-none inline-block h-6 transform rounded-md  bg-primary-400/10 dark:bg-primary-400/10 shadow transition duration-200 ease-in-out z-0 relative',
        active: 'text-text-1 dark:text-text-1',
        inactive: 'text-gray-500 dark:text-gray-400',
        base: 'absolute inset-y-0 w-1/2 flex items-center justify-center pointer-events-none z-[1] transition-colors duration-200 select-none text-xs font-semibold flex-shrink-0',
        left: 'left-0',
        right: 'right-0',
      },
      /*       card: {
        wrapper:
          'relative flex flex-col self-stretch w-full ring-0 bg-gradient-to-b from-block-2 to-transparent dark:from-block-2 dark:to-black/0 bg-transparent dark:bg-transparent',
        highlight:
          'ring-1 ring-primary dark:ring-primary bg-bg dark:bg-bg from-block-3 to-white/0 dark:from-block-3 dark:to-black/0',
        scale: 'lg:scale-[1.1] lg:z-10',
        rounded: 'rounded-xl',
        body: {
          base: 'flex-1 gap-6 lg:gap-x-8 xl:gap-x-10 flex flex-col',
          padding: 'p-6 lg:p-8 xl:p-10',
        },
        inner: 'flex items-center gap-3',
        title:
          't-subtitle__bold text-subtitle sm:text-subtitle text-text-1 dark:text-text-1 sm:t-subtitle__bold',
        description:
          'text-body sm:text-body t-body text-text-3 dark:text-text-3 sm:text-text-3 mt-2',
        amount: {
          discount:
            'text-text-2 dark:text-text-2 t-subtitle sm:t-subtitle text-subtitle sm:text-subtitle',
          price:
            'text-text-1 dark:text-text-1 text-h4 sm:text-h4 t-heading-4 font-semibold',
          cycle:
            'text-gray-500 dark:text-gray-400 text-sm/6 font-medium truncate',
        },
        features: {
          vertical: 'space-y-3 text-sm',
          horizontal: 'grid lg:grid-cols-2 text-sm gap-3',
          item: {
            base: 'flex items-center gap-x-3 min-w-0',
            label: 'text-gray-600 dark:text-gray-400 truncate',
            icon: {
              base: 'w-5 h-5 flex-shrink-0 text-primary',
              name: 'i-heroicons-check-circle-20-solid',
            },
          },
        },
        divider: 'my-6 lg:my-8',
        left: '',
        right: '',
      }, */
    },
  },
})
