import { default as changelogsaUcnbuokEMeta } from "/vercel/path0/pages/changelog.vue?macro=true";
import { default as _91_46_46_46slug_93JlimmfwmWUMeta } from "/vercel/path0/pages/documentation/[...slug].vue?macro=true";
import { default as eu_45fundsOeRAhrFXvOMeta } from "/vercel/path0/pages/eu-funds.vue?macro=true";
import { default as dashboardBcxD9ZKZZfMeta } from "/vercel/path0/pages/features/dashboard.vue?macro=true";
import { default as docs0F0PXsE3wfMeta } from "/vercel/path0/pages/features/docs.vue?macro=true";
import { default as issueFmdh7zYcsMMeta } from "/vercel/path0/pages/features/issue.vue?macro=true";
import { default as roadmap8iZLHB3mxwMeta } from "/vercel/path0/pages/features/roadmap.vue?macro=true";
import { default as testingwtoorx8UTCMeta } from "/vercel/path0/pages/features/testing.vue?macro=true";
import { default as timesheet1D89DTYCDIMeta } from "/vercel/path0/pages/features/timesheet.vue?macro=true";
import { default as contacts7h2iwxNAbfMeta } from "/vercel/path0/pages/help/contacts.vue?macro=true";
import { default as faqSEcbwJjIG9Meta } from "/vercel/path0/pages/help/faq.vue?macro=true";
import { default as helpgTNWcaY3gjMeta } from "/vercel/path0/pages/help.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as partnerJAVlLdrtbgMeta } from "/vercel/path0/pages/partner.vue?macro=true";
import { default as pricingIYNdvfoSO6Meta } from "/vercel/path0/pages/pricing.vue?macro=true";
import { default as component_45stubUHgG5aOkzGMeta } from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
export default [
  {
    name: changelogsaUcnbuokEMeta?.name ?? "changelog___en",
    path: changelogsaUcnbuokEMeta?.path ?? "/changelog",
    meta: changelogsaUcnbuokEMeta || {},
    alias: changelogsaUcnbuokEMeta?.alias || [],
    redirect: changelogsaUcnbuokEMeta?.redirect,
    component: () => import("/vercel/path0/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: changelogsaUcnbuokEMeta?.name ?? "changelog___de",
    path: changelogsaUcnbuokEMeta?.path ?? "/de/changelog",
    meta: changelogsaUcnbuokEMeta || {},
    alias: changelogsaUcnbuokEMeta?.alias || [],
    redirect: changelogsaUcnbuokEMeta?.redirect,
    component: () => import("/vercel/path0/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: changelogsaUcnbuokEMeta?.name ?? "changelog___pl",
    path: changelogsaUcnbuokEMeta?.path ?? "/pl/changelog",
    meta: changelogsaUcnbuokEMeta || {},
    alias: changelogsaUcnbuokEMeta?.alias || [],
    redirect: changelogsaUcnbuokEMeta?.redirect,
    component: () => import("/vercel/path0/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: changelogsaUcnbuokEMeta?.name ?? "changelog___cs",
    path: changelogsaUcnbuokEMeta?.path ?? "/cs/changelog",
    meta: changelogsaUcnbuokEMeta || {},
    alias: changelogsaUcnbuokEMeta?.alias || [],
    redirect: changelogsaUcnbuokEMeta?.redirect,
    component: () => import("/vercel/path0/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93JlimmfwmWUMeta?.name ?? "documentation-slug___en",
    path: _91_46_46_46slug_93JlimmfwmWUMeta?.path ?? "/documentation/:slug(.*)*",
    meta: _91_46_46_46slug_93JlimmfwmWUMeta || {},
    alias: _91_46_46_46slug_93JlimmfwmWUMeta?.alias || [],
    redirect: _91_46_46_46slug_93JlimmfwmWUMeta?.redirect,
    component: () => import("/vercel/path0/pages/documentation/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93JlimmfwmWUMeta?.name ?? "documentation-slug___de",
    path: _91_46_46_46slug_93JlimmfwmWUMeta?.path ?? "/de/documentation/:slug(.*)*",
    meta: _91_46_46_46slug_93JlimmfwmWUMeta || {},
    alias: _91_46_46_46slug_93JlimmfwmWUMeta?.alias || [],
    redirect: _91_46_46_46slug_93JlimmfwmWUMeta?.redirect,
    component: () => import("/vercel/path0/pages/documentation/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93JlimmfwmWUMeta?.name ?? "documentation-slug___pl",
    path: _91_46_46_46slug_93JlimmfwmWUMeta?.path ?? "/pl/documentation/:slug(.*)*",
    meta: _91_46_46_46slug_93JlimmfwmWUMeta || {},
    alias: _91_46_46_46slug_93JlimmfwmWUMeta?.alias || [],
    redirect: _91_46_46_46slug_93JlimmfwmWUMeta?.redirect,
    component: () => import("/vercel/path0/pages/documentation/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93JlimmfwmWUMeta?.name ?? "documentation-slug___cs",
    path: _91_46_46_46slug_93JlimmfwmWUMeta?.path ?? "/cs/documentation/:slug(.*)*",
    meta: _91_46_46_46slug_93JlimmfwmWUMeta || {},
    alias: _91_46_46_46slug_93JlimmfwmWUMeta?.alias || [],
    redirect: _91_46_46_46slug_93JlimmfwmWUMeta?.redirect,
    component: () => import("/vercel/path0/pages/documentation/[...slug].vue").then(m => m.default || m)
  },
  {
    name: eu_45fundsOeRAhrFXvOMeta?.name ?? "eu-funds___en",
    path: eu_45fundsOeRAhrFXvOMeta?.path ?? "/eu-funds",
    meta: eu_45fundsOeRAhrFXvOMeta || {},
    alias: eu_45fundsOeRAhrFXvOMeta?.alias || [],
    redirect: eu_45fundsOeRAhrFXvOMeta?.redirect,
    component: () => import("/vercel/path0/pages/eu-funds.vue").then(m => m.default || m)
  },
  {
    name: eu_45fundsOeRAhrFXvOMeta?.name ?? "eu-funds___de",
    path: eu_45fundsOeRAhrFXvOMeta?.path ?? "/de/eu-funds",
    meta: eu_45fundsOeRAhrFXvOMeta || {},
    alias: eu_45fundsOeRAhrFXvOMeta?.alias || [],
    redirect: eu_45fundsOeRAhrFXvOMeta?.redirect,
    component: () => import("/vercel/path0/pages/eu-funds.vue").then(m => m.default || m)
  },
  {
    name: eu_45fundsOeRAhrFXvOMeta?.name ?? "eu-funds___pl",
    path: eu_45fundsOeRAhrFXvOMeta?.path ?? "/pl/eu-funds",
    meta: eu_45fundsOeRAhrFXvOMeta || {},
    alias: eu_45fundsOeRAhrFXvOMeta?.alias || [],
    redirect: eu_45fundsOeRAhrFXvOMeta?.redirect,
    component: () => import("/vercel/path0/pages/eu-funds.vue").then(m => m.default || m)
  },
  {
    name: eu_45fundsOeRAhrFXvOMeta?.name ?? "eu-funds___cs",
    path: eu_45fundsOeRAhrFXvOMeta?.path ?? "/cs/eu-funds",
    meta: eu_45fundsOeRAhrFXvOMeta || {},
    alias: eu_45fundsOeRAhrFXvOMeta?.alias || [],
    redirect: eu_45fundsOeRAhrFXvOMeta?.redirect,
    component: () => import("/vercel/path0/pages/eu-funds.vue").then(m => m.default || m)
  },
  {
    name: dashboardBcxD9ZKZZfMeta?.name ?? "features-dashboard___en",
    path: dashboardBcxD9ZKZZfMeta?.path ?? "/features/dashboard",
    meta: dashboardBcxD9ZKZZfMeta || {},
    alias: dashboardBcxD9ZKZZfMeta?.alias || [],
    redirect: dashboardBcxD9ZKZZfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardBcxD9ZKZZfMeta?.name ?? "features-dashboard___de",
    path: dashboardBcxD9ZKZZfMeta?.path ?? "/de/features/dashboard",
    meta: dashboardBcxD9ZKZZfMeta || {},
    alias: dashboardBcxD9ZKZZfMeta?.alias || [],
    redirect: dashboardBcxD9ZKZZfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardBcxD9ZKZZfMeta?.name ?? "features-dashboard___pl",
    path: dashboardBcxD9ZKZZfMeta?.path ?? "/pl/features/dashboard",
    meta: dashboardBcxD9ZKZZfMeta || {},
    alias: dashboardBcxD9ZKZZfMeta?.alias || [],
    redirect: dashboardBcxD9ZKZZfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/dashboard.vue").then(m => m.default || m)
  },
  {
    name: dashboardBcxD9ZKZZfMeta?.name ?? "features-dashboard___cs",
    path: dashboardBcxD9ZKZZfMeta?.path ?? "/cs/features/dashboard",
    meta: dashboardBcxD9ZKZZfMeta || {},
    alias: dashboardBcxD9ZKZZfMeta?.alias || [],
    redirect: dashboardBcxD9ZKZZfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/dashboard.vue").then(m => m.default || m)
  },
  {
    name: docs0F0PXsE3wfMeta?.name ?? "features-docs___en",
    path: docs0F0PXsE3wfMeta?.path ?? "/features/docs",
    meta: docs0F0PXsE3wfMeta || {},
    alias: docs0F0PXsE3wfMeta?.alias || [],
    redirect: docs0F0PXsE3wfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/docs.vue").then(m => m.default || m)
  },
  {
    name: docs0F0PXsE3wfMeta?.name ?? "features-docs___de",
    path: docs0F0PXsE3wfMeta?.path ?? "/de/features/docs",
    meta: docs0F0PXsE3wfMeta || {},
    alias: docs0F0PXsE3wfMeta?.alias || [],
    redirect: docs0F0PXsE3wfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/docs.vue").then(m => m.default || m)
  },
  {
    name: docs0F0PXsE3wfMeta?.name ?? "features-docs___pl",
    path: docs0F0PXsE3wfMeta?.path ?? "/pl/features/docs",
    meta: docs0F0PXsE3wfMeta || {},
    alias: docs0F0PXsE3wfMeta?.alias || [],
    redirect: docs0F0PXsE3wfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/docs.vue").then(m => m.default || m)
  },
  {
    name: docs0F0PXsE3wfMeta?.name ?? "features-docs___cs",
    path: docs0F0PXsE3wfMeta?.path ?? "/cs/features/docs",
    meta: docs0F0PXsE3wfMeta || {},
    alias: docs0F0PXsE3wfMeta?.alias || [],
    redirect: docs0F0PXsE3wfMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/docs.vue").then(m => m.default || m)
  },
  {
    name: issueFmdh7zYcsMMeta?.name ?? "features-issue___en",
    path: issueFmdh7zYcsMMeta?.path ?? "/features/issue",
    meta: issueFmdh7zYcsMMeta || {},
    alias: issueFmdh7zYcsMMeta?.alias || [],
    redirect: issueFmdh7zYcsMMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/issue.vue").then(m => m.default || m)
  },
  {
    name: issueFmdh7zYcsMMeta?.name ?? "features-issue___de",
    path: issueFmdh7zYcsMMeta?.path ?? "/de/features/issue",
    meta: issueFmdh7zYcsMMeta || {},
    alias: issueFmdh7zYcsMMeta?.alias || [],
    redirect: issueFmdh7zYcsMMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/issue.vue").then(m => m.default || m)
  },
  {
    name: issueFmdh7zYcsMMeta?.name ?? "features-issue___pl",
    path: issueFmdh7zYcsMMeta?.path ?? "/pl/features/issue",
    meta: issueFmdh7zYcsMMeta || {},
    alias: issueFmdh7zYcsMMeta?.alias || [],
    redirect: issueFmdh7zYcsMMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/issue.vue").then(m => m.default || m)
  },
  {
    name: issueFmdh7zYcsMMeta?.name ?? "features-issue___cs",
    path: issueFmdh7zYcsMMeta?.path ?? "/cs/features/issue",
    meta: issueFmdh7zYcsMMeta || {},
    alias: issueFmdh7zYcsMMeta?.alias || [],
    redirect: issueFmdh7zYcsMMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/issue.vue").then(m => m.default || m)
  },
  {
    name: roadmap8iZLHB3mxwMeta?.name ?? "features-roadmap___en",
    path: roadmap8iZLHB3mxwMeta?.path ?? "/features/roadmap",
    meta: roadmap8iZLHB3mxwMeta || {},
    alias: roadmap8iZLHB3mxwMeta?.alias || [],
    redirect: roadmap8iZLHB3mxwMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap8iZLHB3mxwMeta?.name ?? "features-roadmap___de",
    path: roadmap8iZLHB3mxwMeta?.path ?? "/de/features/roadmap",
    meta: roadmap8iZLHB3mxwMeta || {},
    alias: roadmap8iZLHB3mxwMeta?.alias || [],
    redirect: roadmap8iZLHB3mxwMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap8iZLHB3mxwMeta?.name ?? "features-roadmap___pl",
    path: roadmap8iZLHB3mxwMeta?.path ?? "/pl/features/roadmap",
    meta: roadmap8iZLHB3mxwMeta || {},
    alias: roadmap8iZLHB3mxwMeta?.alias || [],
    redirect: roadmap8iZLHB3mxwMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/roadmap.vue").then(m => m.default || m)
  },
  {
    name: roadmap8iZLHB3mxwMeta?.name ?? "features-roadmap___cs",
    path: roadmap8iZLHB3mxwMeta?.path ?? "/cs/features/roadmap",
    meta: roadmap8iZLHB3mxwMeta || {},
    alias: roadmap8iZLHB3mxwMeta?.alias || [],
    redirect: roadmap8iZLHB3mxwMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/roadmap.vue").then(m => m.default || m)
  },
  {
    name: testingwtoorx8UTCMeta?.name ?? "features-testing___en",
    path: testingwtoorx8UTCMeta?.path ?? "/features/testing",
    meta: testingwtoorx8UTCMeta || {},
    alias: testingwtoorx8UTCMeta?.alias || [],
    redirect: testingwtoorx8UTCMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/testing.vue").then(m => m.default || m)
  },
  {
    name: testingwtoorx8UTCMeta?.name ?? "features-testing___de",
    path: testingwtoorx8UTCMeta?.path ?? "/de/features/testing",
    meta: testingwtoorx8UTCMeta || {},
    alias: testingwtoorx8UTCMeta?.alias || [],
    redirect: testingwtoorx8UTCMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/testing.vue").then(m => m.default || m)
  },
  {
    name: testingwtoorx8UTCMeta?.name ?? "features-testing___pl",
    path: testingwtoorx8UTCMeta?.path ?? "/pl/features/testing",
    meta: testingwtoorx8UTCMeta || {},
    alias: testingwtoorx8UTCMeta?.alias || [],
    redirect: testingwtoorx8UTCMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/testing.vue").then(m => m.default || m)
  },
  {
    name: testingwtoorx8UTCMeta?.name ?? "features-testing___cs",
    path: testingwtoorx8UTCMeta?.path ?? "/cs/features/testing",
    meta: testingwtoorx8UTCMeta || {},
    alias: testingwtoorx8UTCMeta?.alias || [],
    redirect: testingwtoorx8UTCMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/testing.vue").then(m => m.default || m)
  },
  {
    name: timesheet1D89DTYCDIMeta?.name ?? "features-timesheet___en",
    path: timesheet1D89DTYCDIMeta?.path ?? "/features/timesheet",
    meta: timesheet1D89DTYCDIMeta || {},
    alias: timesheet1D89DTYCDIMeta?.alias || [],
    redirect: timesheet1D89DTYCDIMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/timesheet.vue").then(m => m.default || m)
  },
  {
    name: timesheet1D89DTYCDIMeta?.name ?? "features-timesheet___de",
    path: timesheet1D89DTYCDIMeta?.path ?? "/de/features/timesheet",
    meta: timesheet1D89DTYCDIMeta || {},
    alias: timesheet1D89DTYCDIMeta?.alias || [],
    redirect: timesheet1D89DTYCDIMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/timesheet.vue").then(m => m.default || m)
  },
  {
    name: timesheet1D89DTYCDIMeta?.name ?? "features-timesheet___pl",
    path: timesheet1D89DTYCDIMeta?.path ?? "/pl/features/timesheet",
    meta: timesheet1D89DTYCDIMeta || {},
    alias: timesheet1D89DTYCDIMeta?.alias || [],
    redirect: timesheet1D89DTYCDIMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/timesheet.vue").then(m => m.default || m)
  },
  {
    name: timesheet1D89DTYCDIMeta?.name ?? "features-timesheet___cs",
    path: timesheet1D89DTYCDIMeta?.path ?? "/cs/features/timesheet",
    meta: timesheet1D89DTYCDIMeta || {},
    alias: timesheet1D89DTYCDIMeta?.alias || [],
    redirect: timesheet1D89DTYCDIMeta?.redirect,
    component: () => import("/vercel/path0/pages/features/timesheet.vue").then(m => m.default || m)
  },
  {
    name: helpgTNWcaY3gjMeta?.name ?? "help___en",
    path: helpgTNWcaY3gjMeta?.path ?? "/help",
    meta: helpgTNWcaY3gjMeta || {},
    alias: helpgTNWcaY3gjMeta?.alias || [],
    redirect: helpgTNWcaY3gjMeta?.redirect,
    component: () => import("/vercel/path0/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: contacts7h2iwxNAbfMeta?.name ?? "help-contacts___en",
    path: contacts7h2iwxNAbfMeta?.path ?? "contacts",
    meta: contacts7h2iwxNAbfMeta || {},
    alias: contacts7h2iwxNAbfMeta?.alias || [],
    redirect: contacts7h2iwxNAbfMeta?.redirect,
    component: () => import("/vercel/path0/pages/help/contacts.vue").then(m => m.default || m)
  },
  {
    name: faqSEcbwJjIG9Meta?.name ?? "help-faq___en",
    path: faqSEcbwJjIG9Meta?.path ?? "faq",
    meta: faqSEcbwJjIG9Meta || {},
    alias: faqSEcbwJjIG9Meta?.alias || [],
    redirect: faqSEcbwJjIG9Meta?.redirect,
    component: () => import("/vercel/path0/pages/help/faq.vue").then(m => m.default || m)
  }
]
  },
  {
    name: helpgTNWcaY3gjMeta?.name ?? "help___de",
    path: helpgTNWcaY3gjMeta?.path ?? "/de/help",
    meta: helpgTNWcaY3gjMeta || {},
    alias: helpgTNWcaY3gjMeta?.alias || [],
    redirect: helpgTNWcaY3gjMeta?.redirect,
    component: () => import("/vercel/path0/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: contacts7h2iwxNAbfMeta?.name ?? "help-contacts___de",
    path: contacts7h2iwxNAbfMeta?.path ?? "contacts",
    meta: contacts7h2iwxNAbfMeta || {},
    alias: contacts7h2iwxNAbfMeta?.alias || [],
    redirect: contacts7h2iwxNAbfMeta?.redirect,
    component: () => import("/vercel/path0/pages/help/contacts.vue").then(m => m.default || m)
  },
  {
    name: faqSEcbwJjIG9Meta?.name ?? "help-faq___de",
    path: faqSEcbwJjIG9Meta?.path ?? "faq",
    meta: faqSEcbwJjIG9Meta || {},
    alias: faqSEcbwJjIG9Meta?.alias || [],
    redirect: faqSEcbwJjIG9Meta?.redirect,
    component: () => import("/vercel/path0/pages/help/faq.vue").then(m => m.default || m)
  }
]
  },
  {
    name: helpgTNWcaY3gjMeta?.name ?? "help___pl",
    path: helpgTNWcaY3gjMeta?.path ?? "/pl/help",
    meta: helpgTNWcaY3gjMeta || {},
    alias: helpgTNWcaY3gjMeta?.alias || [],
    redirect: helpgTNWcaY3gjMeta?.redirect,
    component: () => import("/vercel/path0/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: contacts7h2iwxNAbfMeta?.name ?? "help-contacts___pl",
    path: contacts7h2iwxNAbfMeta?.path ?? "contacts",
    meta: contacts7h2iwxNAbfMeta || {},
    alias: contacts7h2iwxNAbfMeta?.alias || [],
    redirect: contacts7h2iwxNAbfMeta?.redirect,
    component: () => import("/vercel/path0/pages/help/contacts.vue").then(m => m.default || m)
  },
  {
    name: faqSEcbwJjIG9Meta?.name ?? "help-faq___pl",
    path: faqSEcbwJjIG9Meta?.path ?? "faq",
    meta: faqSEcbwJjIG9Meta || {},
    alias: faqSEcbwJjIG9Meta?.alias || [],
    redirect: faqSEcbwJjIG9Meta?.redirect,
    component: () => import("/vercel/path0/pages/help/faq.vue").then(m => m.default || m)
  }
]
  },
  {
    name: helpgTNWcaY3gjMeta?.name ?? "help___cs",
    path: helpgTNWcaY3gjMeta?.path ?? "/cs/help",
    meta: helpgTNWcaY3gjMeta || {},
    alias: helpgTNWcaY3gjMeta?.alias || [],
    redirect: helpgTNWcaY3gjMeta?.redirect,
    component: () => import("/vercel/path0/pages/help.vue").then(m => m.default || m),
    children: [
  {
    name: contacts7h2iwxNAbfMeta?.name ?? "help-contacts___cs",
    path: contacts7h2iwxNAbfMeta?.path ?? "contacts",
    meta: contacts7h2iwxNAbfMeta || {},
    alias: contacts7h2iwxNAbfMeta?.alias || [],
    redirect: contacts7h2iwxNAbfMeta?.redirect,
    component: () => import("/vercel/path0/pages/help/contacts.vue").then(m => m.default || m)
  },
  {
    name: faqSEcbwJjIG9Meta?.name ?? "help-faq___cs",
    path: faqSEcbwJjIG9Meta?.path ?? "faq",
    meta: faqSEcbwJjIG9Meta || {},
    alias: faqSEcbwJjIG9Meta?.alias || [],
    redirect: faqSEcbwJjIG9Meta?.redirect,
    component: () => import("/vercel/path0/pages/help/faq.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___en",
    path: indexe9Brt5DfdhMeta?.path ?? "/",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___de",
    path: indexe9Brt5DfdhMeta?.path ?? "/de",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___pl",
    path: indexe9Brt5DfdhMeta?.path ?? "/pl",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexe9Brt5DfdhMeta?.name ?? "index___cs",
    path: indexe9Brt5DfdhMeta?.path ?? "/cs",
    meta: indexe9Brt5DfdhMeta || {},
    alias: indexe9Brt5DfdhMeta?.alias || [],
    redirect: indexe9Brt5DfdhMeta?.redirect,
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: partnerJAVlLdrtbgMeta?.name ?? "partner___en",
    path: partnerJAVlLdrtbgMeta?.path ?? "/partner",
    meta: partnerJAVlLdrtbgMeta || {},
    alias: partnerJAVlLdrtbgMeta?.alias || [],
    redirect: partnerJAVlLdrtbgMeta?.redirect,
    component: () => import("/vercel/path0/pages/partner.vue").then(m => m.default || m)
  },
  {
    name: partnerJAVlLdrtbgMeta?.name ?? "partner___de",
    path: partnerJAVlLdrtbgMeta?.path ?? "/de/partner",
    meta: partnerJAVlLdrtbgMeta || {},
    alias: partnerJAVlLdrtbgMeta?.alias || [],
    redirect: partnerJAVlLdrtbgMeta?.redirect,
    component: () => import("/vercel/path0/pages/partner.vue").then(m => m.default || m)
  },
  {
    name: partnerJAVlLdrtbgMeta?.name ?? "partner___pl",
    path: partnerJAVlLdrtbgMeta?.path ?? "/pl/partner",
    meta: partnerJAVlLdrtbgMeta || {},
    alias: partnerJAVlLdrtbgMeta?.alias || [],
    redirect: partnerJAVlLdrtbgMeta?.redirect,
    component: () => import("/vercel/path0/pages/partner.vue").then(m => m.default || m)
  },
  {
    name: partnerJAVlLdrtbgMeta?.name ?? "partner___cs",
    path: partnerJAVlLdrtbgMeta?.path ?? "/cs/partner",
    meta: partnerJAVlLdrtbgMeta || {},
    alias: partnerJAVlLdrtbgMeta?.alias || [],
    redirect: partnerJAVlLdrtbgMeta?.redirect,
    component: () => import("/vercel/path0/pages/partner.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___en",
    path: pricingIYNdvfoSO6Meta?.path ?? "/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___de",
    path: pricingIYNdvfoSO6Meta?.path ?? "/de/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___pl",
    path: pricingIYNdvfoSO6Meta?.path ?? "/pl/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: pricingIYNdvfoSO6Meta?.name ?? "pricing___cs",
    path: pricingIYNdvfoSO6Meta?.path ?? "/cs/pricing",
    meta: pricingIYNdvfoSO6Meta || {},
    alias: pricingIYNdvfoSO6Meta?.alias || [],
    redirect: pricingIYNdvfoSO6Meta?.redirect,
    component: () => import("/vercel/path0/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: component_45stubUHgG5aOkzGMeta?.name ?? undefined,
    path: component_45stubUHgG5aOkzGMeta?.path ?? "/sitemap.xml",
    meta: component_45stubUHgG5aOkzGMeta || {},
    alias: component_45stubUHgG5aOkzGMeta?.alias || [],
    redirect: component_45stubUHgG5aOkzGMeta?.redirect,
    component: () => import("/vercel/path0/node_modules/nuxt/dist/pages/runtime/component-stub").then(m => m.default || m)
  }
]