<template>
  <transition
    name="fade-in"
    appear
  >
    <div
      v-show="cookieConsentMode !== 'hidden'"
      class="fixed bottom-0 left-0 right-0 z-10 w-screen overflow-y-auto border-t border-block-4 bg-block-4 py-4 backdrop-blur-2xl md:py-8"
      style="max-height: calc(100vh - 68px)"
    >
      <UContainer>
        <div
          v-if="cookieConsentMode === 'settings'"
          class="container mx-auto flex flex-col"
        >
          <span class="t-heading-4 mb-3 text-text-1">{{
            t('c.cookieSettings')
          }}</span>
          <p class="t-body mb-6 text-text-2">
            {{ t('c.cookieSettingsDesc') }}
          </p>

          <div class="mb-6 mt-4 flex flex-col gap-4">
            <div
              v-for="item in settingsItems"
              :key="item.keyword"
            >
              <div class="mb-1 flex items-center">
                <UToggle
                  v-model="data[item.keyword]"
                  on-icon="i-heroicons-check-20-solid"
                  off-icon="i-heroicons-x-mark-20-solid"
                  :disabled="item.disabled"
                  class="mr-3"
                  :aria-label="item.title"
                />
                <div class="flex flex-col">
                  <span class="t-body text-text-1">{{ item.title }}</span>
                  <p class="t-medium text-text-2">
                    {{ item.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <UButton
            class="self-end"
            @click="acceptSelected()"
          >
            {{ t('c.acceptSelected') }}
          </UButton>
        </div>
      </UContainer>
    </div>
  </transition>
</template>
<script setup lang="ts">
const { $posthog } = useNuxtApp()
const { t } = useI18n()

const maxAgeInSeconds = 30 * 24 * 60 * 60
const postHogClient = $posthog()

const settingsItems = ref([
  {
    title: 'Functional cookies (required)',
    description:
      'Essential cookies that ensure that the website functions properly and that your preferences (e.g. language, region) are saved. These cookies can’t be denied.',
    disabled: true,
    keyword: 'functional',
  },
  {
    title: 'Analytical cookies (optional)',
    description:
      'Allow us to analyse the website use and to improve your experience.',
    disabled: false,
    keyword: 'analytical',
  },
])

const cookieConsentMode = useState<'hidden' | 'banner' | 'settings'>(
  'cookieConsentMode',
  () => 'hidden'
)

const data = ref<{ [K: string]: boolean }>({})
const cookiesAnswered = useCookie<boolean>('cookiesSelected', {
  default: () => false,
  maxAge: maxAgeInSeconds,
})

function getKeywords() {
  return settingsItems.value.map(({ keyword }) => keyword)
}

function readCookies(keywords: string[]): { [K: string]: boolean } {
  const data: { [K: string]: boolean } = {}

  keywords.forEach((keyword) => {
    const currentCookie = useCookie<boolean | undefined>(keyword)
    data[keyword] =
      keyword === 'functional' ? true : currentCookie.value || true
  })

  return data
}

function acceptSelected() {
  saveCookies()
  // @ts-ignore
  postHogClient?.opt_out_capturing()
}

function saveCookies(withConfirmation = true) {
  const keywords = getKeywords()

  keywords.forEach((keyword) => {
    const currentCookie = useCookie<boolean | undefined>(keyword, {
      maxAge: maxAgeInSeconds,
    })

    currentCookie.value = data.value[keyword] || false
  })

  if (withConfirmation) {
    cookiesAnswered.value = true
  }

  cookieConsentMode.value = 'hidden'
}

function created() {
  data.value = readCookies(getKeywords())
  saveCookies(false)

  if (!cookiesAnswered.value) {
    cookieConsentMode.value = 'settings'
  }
}

created()
</script>
<style lang="scss" scoped>
.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 0.4s ease-in-out;
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}
</style>
